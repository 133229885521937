<template>
  <n-modal v-bind="$props">
    <!-- prettier-ignore -->
    <Form ref="modalForm" class="n-modal-container" :label-width="labelWidth" :model="model" @submit.native.prevent>
      <n-row-col2>
        <n-field-id v-model="model.id" label="id" disabled />
        <!-- name -->
        <n-field-text v-model="model.name" field-name="name" label="name" :error="error.name" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <ns-company v-model="model.companyId" :error="error.companyId" :disabled="!isCreate || disabled" />
        <n-field-enum v-model="model.type" :error="error.type" enum-name="TransactionType" label="type" :disabled="isEdit || disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-id v-model="model.customerId" field-name="customerId" label="customerId" :error="error.customerId" :disabled="isEdit || disabled" />
        <n-field-enum v-model="model.status" :error="error.status" enum-name="CustomerCategoryStatus" label="status" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- parentId -->
        <n-field-id v-model="model.parentId" label="parent" disabled />
        <!-- icon -->
        <n-field-selection v-model="model.icon" field-name="icon" selection-name="market.icon" :error="error.icon" label="icon" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-id v-model="model.sourceId" label="sourceId" disabled />
        <!-- name -->
        <n-field-text v-model="model.displayOrder" field-name="displayOrder" label="displayOrder" :error="error.displayOrder" :disabled="disabled" />
      </n-row-col2>
      <n-row-col1>
        <!-- i18nKey -->
        <n-field-text v-model="model.i18nKey" field-name="i18nKey" label="i18nKey" :error="error.i18nKey" disabled />
      </n-row-col1>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { validate, update, add } from '@/api/customer/customer-customer-category';

export default NModal.extend({
  name: 'ModalCustomerCategory',
  components: { NModal },

  methods: {
    doValidate() {
      return validate(this.model);
    },

    doSubmit() {
      return this.isCreate ? add(this.model) : update(this.model);
    },
  },
});
</script>
