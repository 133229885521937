import {$fetch, $post, $put} from '@/services/axios';

export function fetch(params) {
  return $fetch(`customer/categories`, params);
}

export const CUSTOMER_CATEGORY_CSV_URL = `customer/categories/csv`;

export function validate(model) {
  return $post(`customer/category/validate`, model);
}

export function add(model) {
  return $put(`customer/category`, model);
}

export function update(model) {
  return $post(`customer/category/${model.id}`, model);
}
